import { useState, useEffect } from "react";
// react-router components
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Argon Dashboard 2 PRO MUI components
import Sidenav from "components/Sidenav";
import Configurator from "components/Configurator";
// Argon Dashboard 2 PRO MUI themes
import theme from "assets/theme";
import themeDark from "assets/theme-dark";

// Images
import brand from "assets/images/GTAC.png";
import brandDark from "assets/images/GTAC.png";

// Icon Fonts
import "assets/css/nucleo-icons.css";
import "assets/css/nucleo-svg.css";
import "assets/css/override.css";

// Argon Dashboard 2 PRO MUI contexts
import { useArgonController, setMiniSidenav } from "context";

import useAuth from "utils/useAuth";
import routes from "routes";
import Error404 from "pages/error-page/404/Error404";
import AllServices from "./pages/taf-management/component/AllServices";
import ServiceInformation from "./pages/taf-management/component/ServiceInformation";

export default function App() {
  const [controller, dispatch] = useArgonController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor, darkSidenav, darkMode } =
    controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const navbarRefresher = (caller) => {
    console.log("Refreshing navbar from " + caller + " in App.js");
    setUpdatedRoutesCount(updateRoutesCount + 1);
  }

  const [updateRoutesCount, setUpdatedRoutesCount] = useState(0);

  const isLoggedIn = useAuth().ensureLoggedIn();

  const navigate = useNavigate();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (!isLoggedIn) {
      console.log("App.js not logged in");
      if (!location.pathname.endsWith("sign-in")) {
        navigate('/sign-in');
      }

      return;
    }

    let doWork = async () => {

    };

    doWork();
  }, [updateRoutesCount]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        isLoggedIn && <>
          <Sidenav
            color={sidenavColor}
            brand={darkSidenav || darkMode ? brand : brandDark}
            brandName="TAF"
            routes={routes(navbarRefresher)}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/*configsButton*/}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes(navbarRefresher))}
        <Route path="*" element={<Error404 />} key="error-404" />
        <Route path="/services" element={<AllServices />} key="services" />
        <Route path="/service_information" element={<ServiceInformation />} key="service information" />
      </Routes>
    </ThemeProvider>
  );
}
