// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import typography from "assets/theme/base/typography";
import { SnackbarProvider } from "notistack";

function Footer() {

  const { size } = typography;

  return (
    <div>
      <SnackbarProvider maxSnack={5} />
      <ArgonBox
        width="100%"
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems="center"
        px={1.5}
      >
        <ArgonBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          color="text"
          fontSize={size.sm}
          px={1.5}
        >
          &copy; {new Date().getFullYear()}, made by
          <Link href={""} target="_blank">
            <ArgonTypography variant="button" fontWeight="medium">
              &nbsp;Concept Quality&nbsp;
            </ArgonTypography>
          </Link>
        </ArgonBox>
        <ArgonBox
          component="ul"
          sx={({ breakpoints }) => ({
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            listStyle: "none",
            mt: 3,
            mb: 0,
            p: 0,

            [breakpoints.up("lg")]: {
              mt: 0,
            },
          })}
        ></ArgonBox>
      </ArgonBox>
    </div>
  );
}

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
