import SignIn from "pages/sign-in/SignIn";
import CreateTaf from "./pages/create-taf/CreateTaf";

// Argon Dashboard 2 PRO MUI components
import LogoutIcon from '@mui/icons-material/Logout';
import {Add, ListAlt} from "@mui/icons-material";
import Management from "./pages/taf-management/Management";


const routes = (navbarRefresher) => [
  {
    type: "link",
    name: "New",
    module: "Create",
    key: "create-taf",
    icon: <Add />,
    route: "create",
    noCollapse: true,
    component: <CreateTaf />,
  },
  {
    type: "link",
    name: "Management",
    module: "Management",
    key: "management-taf",
    icon: <ListAlt />,
    route: "management",
    noCollapse: true,
    component: <Management />,
  },
  {
    type: "link",
    name: "Sign Out",
    key: "sign-out",
    icon: <LogoutIcon />,
    route: "sign-in",
    noCollapse: true,
    onClick: () => {
      // TODO make a cool logout function
      localStorage.removeItem("jwt-token");
      sessionStorage.removeItem("jwt-token");
    },
    component: <SignIn navbarRefresher={navbarRefresher}/>,
  },
];

export default routes;
