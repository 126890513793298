import { Navigate } from "react-router-dom";
import useAuth from "utils/useAuth";
import Footer from "components/Footer";
import Header from "components/Header";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import ArgonBox from "../../../components/ArgonBox";
import ArgonTypography from "../../../components/ArgonTypography";
import ArgonButton from "../../../components/ArgonButton";
import Grid from "@mui/material/Grid";

function Error404() {

  const isLoggedIn = useAuth().ensureLoggedIn();

  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  return (
    <DashboardLayout>
      <Header />
        <Grid container justifyContent="center" mt={"20%"} mb={"10%"}>
          <Grid item xs={12} md={7} lg={6} sx={{ textAlign: "center", mx: "auto" }}>
            <ArgonBox
                color="error"
                fontWeight="bold"
                fontSize={{ xs: "2.125rem", lg: "6rem" }}
                lineHeight={1.2}
                mb={2}
            >
              Error 404
            </ArgonBox>
            <ArgonTypography variant="h2" color="dark" fontWeight="bold">
              Erm. Page not found
            </ArgonTypography>
            <ArgonTypography variant="body1" color="text">
              We suggest you to go to the homepage while we solve this issue.
            </ArgonTypography>
            <ArgonButton variant="gradient" color="dark" sx={{ mt: 5 }}>
              Go to Homepage
            </ArgonButton>
          </Grid>
        </Grid>
      <Footer />
    </DashboardLayout>
  );
}


export default Error404;
