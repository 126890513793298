import {useEffect, useRef, useState} from "react";
import Card from "@mui/material/Card";
import ArgonBox from "../../../components/ArgonBox";
import Table from "../../../components/Table";
import ArgonButton from "../../../components/ArgonButton";
import ArgonBadgeDot from "../../../components/ArgonBadgeDot";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import {ManagementServices} from "../../../api/BackendApi/ManagementServices";
import {CircularProgress, tooltipClasses} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {styled} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";

function TablePods({namespace,service,getLogs}) {

  const mounted = useRef(false);
  const [allPods, setAllPods] = useState(null);

  useEffect(() => {
    async function loadData() {
      mounted.current = true;
      await updateLoop();
    }
    loadData().then(r => console.log("Information loaded"));
  }, []);

  const updateLoop = async () => {
    try {
      if (!mounted.current) return;
      let response_pods = await ManagementServices.get_pod_details(namespace,service);
      setAllPods(response_pods.data);
    } finally {
      setTimeout(updateLoop, 3000);
    }
  }

  const NoMaxWidthTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  });

  let body = <Grid container justifyContent="center" sx={{marginTop: 20, marginBottom: 20}}>
                          <CircularProgress></CircularProgress>
                      </Grid>

  if(allPods != null){
    body = (
      <ArgonBox mt={5} mb={3}>
        <ArgonBox mb={3}>
          <Card>
            <ArgonBox>
              <Table
                  columns={[
                    { name: "pod", align: "center" },
                    { name: "pod_ip", align: "center" },
                    { name: "status", align: "center" },
                    { name: "cpu_usage_percent", align: "center" },
                    { name: "memory_usage_percent", align: "center" },
                    { name: "actions", align: "center" },
                  ]}
                  rows={allPods.pods.map((pod) => {

                    return {
                      pod: pod.name.replace("taf-node-deployment-",""),
                      pod_ip: pod.pod_ip,
                      status: (pod.status === "Running" ? (
                          <ArgonBox ml={-1.325}>
                            <ArgonBadgeDot size="lg" color="success" badgeContent={pod.status} />
                          </ArgonBox>
                      ) : (
                          <ArgonBox ml={-1.325}>
                            <ArgonBadgeDot size="lg" color="error" badgeContent={pod.status} />
                          </ArgonBox>
                      )),
                      cpu_usage_percent: (pod.cpu_usage_percent)?.toFixed(2) + "%",
                      memory_usage_percent: (pod.memory_usage_percent)?.toFixed(2) + "%",
                      actions: (<Grid container spacing={1}>
                                  <Grid item xs={4} textAlign={"start"}>
                                    <NoMaxWidthTooltip  title="Logs" placement="top">
                                      <ArgonButton variant="gradient" color="info" iconOnly
                                                   key={pod.name}
                                                   onClick={() =>  getLogs(pod.name)}>
                                        <Icon>description</Icon>
                                      </ArgonButton>
                                    </NoMaxWidthTooltip>
                                  </Grid>
                                </Grid>),
                    }
                  })}
              />
            </ArgonBox>
          </Card>
        </ArgonBox>
      </ArgonBox>
    );
  }

  return body

}

export default TablePods;
