import ArgonBox from "components/ArgonBox";
import {CircularProgress, FormControlLabel, FormGroup} from "@mui/material";
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {ManagementServices} from "../../../api/BackendApi/ManagementServices";
import ArgonTypography from "../../../components/ArgonTypography";
import Checkbox from "@mui/material/Checkbox";
import ArgonButton from "../../../components/ArgonButton";

function EditService({closeView, namespace}) {

    const [allServices, setAllServices] = useState(null);
    const [allServicesEdited, setAllServicesEdited] = useState([]);

    useEffect(() => {
        async function loadData() {
            let response_services = await ManagementServices.show_all_services(namespace['namespace']);
            setAllServices(response_services.data.services);
        }
        loadData().then(r => console.log("Information loaded"));
    }, []);

    const handleSaveEditServices = () => {
        //TODO
        //ManagementServices.save_edit_services(namespace['namespace'], allServicesEdited).then(r => console.log("Saved edit services"));
        closeView();
    }

    const handleCheckboxEdit = (service_name,value) => {
        if(value){
            if(allServicesEdited.find((element) => {return element.service_name === service_name})){
                setAllServicesEdited([...allServicesEdited.filter((element) => {
                    return element.service_name !== service_name
                })]);
            }else{
                setAllServicesEdited([...allServicesEdited, {service_name: service_name, type: "added"}]);
            }
        }else{
            if(allServicesEdited.find((element) => {return element.service_name === service_name})){
                setAllServicesEdited([...allServicesEdited.filter((element) => {
                    return element.service_name !== service_name
                })]);
            }else{
                setAllServicesEdited([...allServicesEdited, {service_name: service_name, type: "removed"}]);
            }
        }
    }

    let body = <ArgonBox mt={5} mb={3}
                         style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress size={64} />
    </ArgonBox>

    if (allServices != null) {
        body = (
            <Grid container>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={4}>
                        </Grid>
                        <Grid item xs={8}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox defaultChecked={allServices.find((element) => {return element.service_name.includes("fe")})} />}
                                                  onChange={(e) => {
                                                      handleCheckboxEdit("fe", e.target.checked)
                                                  }} label="TAF" />
                                <FormControlLabel control={<Checkbox defaultChecked={allServices.find((element) => {return element.service_name.includes("orchestrator")})} />}
                                                  onChange={(e) => {
                                                      handleCheckboxEdit("orchestrator", e.target.checked)
                                                  }} label="Orchestrator" />
                                <FormControlLabel control={<Checkbox defaultChecked={allServices.find((element) => {return element.service_name.includes("node")})} />}
                                                  onChange={(e) => {
                                                      handleCheckboxEdit("node", e.target.checked)
                                                  }} label="Node" />
                                <FormControlLabel control={<Checkbox defaultChecked={allServices.find((element) => {return element.service_name.includes("chatbot")})} />}
                                                  onChange={(e) => {
                                                      handleCheckboxEdit("chatbot", e.target.checked)
                                                  }} label="Taffy" />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={10}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox defaultChecked={allServices.find((element) => {return element.service_name.includes("map")})} />}
                                                  onChange={(e) => {
                                                      handleCheckboxEdit("map", e.target.checked)
                                                  }} label="Application Explorer" />
                                <FormControlLabel control={<Checkbox defaultChecked={allServices.find((element) => {return element.service_name.includes("generative")})} />}
                                                  onChange={(e) => {
                                                      handleCheckboxEdit("generative", e.target.checked)
                                                  }} label="Generative Test" />
                                <FormControlLabel control={<Checkbox defaultChecked={allServices.find((element) => {return element.service_name.includes("integrationmanagement")})} />}
                                                  onChange={(e) => {
                                                      handleCheckboxEdit("integrationmanagement", e.target.checked)
                                                  }} label="Integration Mngmt Tool" />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5} m={3}>
                    <ArgonButton color="dark" size="large" fullWidth onClick={closeView}>Back</ArgonButton>
                </Grid>
                <Grid item xs={5} m={3}>
                    <ArgonButton color="info" size="large" fullWidth onClick={handleSaveEditServices} disabled={allServicesEdited.length === 0}>Save</ArgonButton>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <ArgonBox>
                    <ArgonTypography textAlign={"center"} fontSize={25} sx={{ fontWeight : "bold" }} mb={1} mt={2}>Services</ArgonTypography>
                    {body}
                </ArgonBox>
            </Grid>
        </Grid>
    );
}


export default EditService;
