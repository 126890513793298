import ArgonBox from "components/ArgonBox";
import {CircularProgress, FormControlLabel, FormGroup} from "@mui/material";
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {ManagementServices} from "../../../api/BackendApi/ManagementServices";
import ArgonTypography from "../../../components/ArgonTypography";
import Checkbox from "@mui/material/Checkbox";
import ArgonButton from "../../../components/ArgonButton";

function DeleteNamespace({closeView, namespace}) {

    const handleDeleteNamespace = () => {
        //TODO
        //ManagementServices.delete_namespace(namespace['namespace']).then(r => console.log("Deleted namespace"));
        closeView();
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <ArgonBox>
                    <ArgonTypography textAlign={"center"} fontSize={25} sx={{ fontWeight : "bold" }} mb={1} mt={2}>Are you sure you want to delete this project?</ArgonTypography>
                    <Grid container>
                        <Grid item xs={5} m={3}>
                            <ArgonButton color="dark" size="large" fullWidth onClick={closeView}>Back</ArgonButton>
                        </Grid>
                        <Grid item xs={5} m={3}>
                            <ArgonButton color="error" size="large" fullWidth onClick={handleDeleteNamespace} >Yes, Delete</ArgonButton>
                        </Grid>
                    </Grid>
                </ArgonBox>
            </Grid>
        </Grid>
    );
}


export default DeleteNamespace;
