import {Navigate, useLocation, useNavigate} from "react-router-dom";

import Header from "components/Header";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import ArgonBox from "components/ArgonBox";
import Dialog from '@mui/material/Dialog';
import useAuth from "utils/useAuth";
import {forwardRef, useEffect, useState} from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import {UsersApi} from "../../../api/BackendApi/Users";
import {ManagementServices} from "../../../api/BackendApi/ManagementServices";
import ArgonButton from "../../../components/ArgonButton";
import Grid from "@mui/material/Grid";
import ArgonTypography from "../../../components/ArgonTypography";
import {enqueueSnackbar} from "notistack";
import LogsView from "./LogsView";
import Fade from "@mui/material/Fade";
import TableServices from "./TableServices";

function AllServices() {

  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);
  const namespace = useState(location.state.namespace);
  const isLoggedIn = useAuth().ensureLoggedIn();
  const [openedServiceLogs, setOpenedServiceLogs] = useState(null);

    useEffect(() => {
        async function loadData() {
            let response = await UsersApi.getMe();
            setUser(response.data.user);
        }
        loadData();
    }, []);

    const handleServiceLogsClose = () => {
        setOpenedServiceLogs(null);
    }

    const Transition = forwardRef(function Transition(props, ref) {
        return <Fade ref={ref} {...props} />;
    });

    const goToManagement = () => {
        navigate('/management');
    }

    const redeployService = async (service_name) => {
        let response = await ManagementServices.redeploy_service(namespace[0],service_name);
        if(response.data) {
            enqueueSnackbar("Service redeployed", {variant: "success"});
        }else{
            enqueueSnackbar("Service not redeployed", {variant: "error"});
        }
    }

    const getLogs = async (service_name) => {
        setOpenedServiceLogs({service: service_name, namespace: namespace[0]});
    }

  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  let body = (
      <ArgonBox mt={10} mb={3}>
        <ArgonBox mb={5}>
            <Card>
                <Grid container sx={{ p: 1 }}>
                    <Grid item xs={4} textAlign={"start"}>
                        <ArgonBox>
                            <ArgonButton variant="gradient" color="info" onClick={() =>  goToManagement()}>
                                <Icon>arrow_back</Icon> Back
                            </ArgonButton>
                        </ArgonBox>
                    </Grid>
                    <Grid item xs={4} textAlign={"center"}>
                        <ArgonBox>
                            <ArgonTypography variant="h3" textTransform="uppercase" sx={"text-align: center"}>{namespace}</ArgonTypography>
                        </ArgonBox>
                    </Grid>
                    <Grid item xs={4} textAlign={"end"}>
                    </Grid>
                </Grid>
            </Card>
        </ArgonBox>
        <TableServices namespace={namespace} redeployService={redeployService} getLogs={getLogs} />
      </ArgonBox>
    );

  return (
    <DashboardLayout>
      <Header />
        {openedServiceLogs != null && <Dialog
            open={true}
            onClose={handleServiceLogsClose}
            fullWidth
            maxWidth={false}
            TransitionComponent={Transition}
            transitionDuration={300}
            keepMounted
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ marginLeft: "6%", marginRight: "6%" }}
        >
            <LogsView closeView={handleServiceLogsClose} service={openedServiceLogs} />
        </Dialog>}
        {body}
      <Footer />
    </DashboardLayout>
  );
}


export default AllServices;
