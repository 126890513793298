
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {ManagementServices} from "../../../api/BackendApi/ManagementServices";

function LogsView({ service }) {

  const mounted = useRef(false);
  const [logs, setLogs] = useState(null);

  const updateLogs = async () => {
    if(service.service !== undefined){
      let response_list_pod = await ManagementServices.get_pods(service.namespace, service.service);
      if(response_list_pod.data.pods.length > 0) {
        let response = await ManagementServices.get_pod_log(service.namespace, response_list_pod.data.pods[0]);
        setLogs(response.data.logs);
      }
    }else{
      let response = await ManagementServices.get_pod_log(service.namespace, service.pod);
      setLogs(response.data.logs);
    }
  }

  const updateLoop = async () => {
    try {
      if (!mounted.current) return;
      await updateLogs();
    } finally {
      setTimeout(updateLoop, 1000);
    }
  }

  useEffect(() => {
    mounted.current = true;
    updateLoop();
    return () => {
      mounted.current = false;
    };
  }, []);

  return <textarea readOnly={true} value={logs ?? "Loading logs..."} style={{
    "width": "100%",
    "height": "85vh",
    "boxSizing": "border-box",
    "backgroundColor": "#000",
    "color": "#fff",
    "padding": "10px",
    "resize": "none"
  }} />;
}

export default LogsView;
