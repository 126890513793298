import {Navigate} from "react-router-dom";
import Header from "components/Header";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import ArgonBox from "components/ArgonBox";
import useAuth from "utils/useAuth";
import {CircularProgress} from "@mui/material";
import {forwardRef, useEffect, useState} from "react";
import {UsersApi} from "../../api/BackendApi/Users";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TableNamespace from "./component/TableNamespace";
import EditService from "./component/EditService";
import Fade from "@mui/material/Fade";
import Dialog from "@mui/material/Dialog";
import DeleteNamespace from "./component/DeleteNamespace";

function Management() {

  const [user, setUser] = useState(null);
  const isLoggedIn = useAuth().ensureLoggedIn();
  const [openedNamespaceInfo, setOpenedNamespaceInfo] = useState(null);
  const [openedEditServices, setOpenedEditServices] = useState(false);
  const [openedDeleteNamespace, setOpenedDeleteNamespace] = useState(false);

  useEffect(() => {
    async function loadData() {
      let response = await UsersApi.getMe();
      setUser(response.data.user);
    }
    loadData().then(r => console.log("Information loaded"));
  }, []);

    const getNamespaceInfo = async (namespace) => {
        setOpenedNamespaceInfo({namespace: namespace});
    }

    const handleNamepsaceInfoClose = () => {
        setOpenedNamespaceInfo(null);
        setOpenedEditServices(false);
        setOpenedDeleteNamespace(false);
    }

    const Transition = forwardRef(function Transition(props, ref) {
        return <Fade ref={ref} {...props} />;
    });

  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  let body = <Grid container justifyContent="center" sx={{marginTop: 40, marginBottom: 20}}>
                          <CircularProgress></CircularProgress>
                      </Grid>

  if (user != null) {
    body = (
      <ArgonBox mt={10} mb={3}>
        <ArgonBox mb={3}>
          <Card>
            <TableNamespace getNamespaceInfo={getNamespaceInfo} setOpenedEditServices={setOpenedEditServices} setOpenedDeleteNamespace={setOpenedDeleteNamespace}/>
          </Card>
        </ArgonBox>
      </ArgonBox>
    );
  }

  return (
    <DashboardLayout>
      <Header />
        {openedEditServices && <Dialog
            open={true}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleNamepsaceInfoClose(event, reason);
                }
            }}
            fullWidth
            maxWidth={"md"}
            TransitionComponent={Transition}
            transitionDuration={300}
            keepMounted
            aria-labelledby="edit-dialog-title"
            aria-describedby="edit-dialog-description"
            sx={{ marginLeft: "6%", marginRight: "6%" }}
        >
            <EditService closeView={handleNamepsaceInfoClose} namespace={openedNamespaceInfo} />
        </Dialog>}
        {openedDeleteNamespace && <Dialog
            open={true}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleNamepsaceInfoClose(event, reason);
                }
            }}
            fullWidth
            maxWidth={"md"}
            TransitionComponent={Transition}
            transitionDuration={300}
            keepMounted
            aria-labelledby="delete-dialog-title"
            aria-describedby="delete-dialog-description"
            sx={{ marginLeft: "6%", marginRight: "6%" }}
        >
            <DeleteNamespace closeView={handleNamepsaceInfoClose} namespace={openedNamespaceInfo} />
        </Dialog>}
        {body}
      <Footer />
    </DashboardLayout>
  );
}

export default Management;
