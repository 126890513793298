import {Navigate,useLocation, useNavigate} from "react-router-dom";

import Header from "components/Header";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import ArgonBox from "components/ArgonBox";
import useAuth from "utils/useAuth";
import {forwardRef, useEffect, useState} from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import {UsersApi} from "../../../api/BackendApi/Users";
import ArgonButton from "../../../components/ArgonButton";
import Grid from "@mui/material/Grid";
import ArgonTypography from "../../../components/ArgonTypography";
import CounterInformation from "./CounterInformation";
import TablePods from "./TablePods";
import Dialog from "@mui/material/Dialog";
import LogsView from "./LogsView";
import Fade from "@mui/material/Fade";

function ServiceInformation() {

  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);
  const namespace = useState(location.state.namespace);
  const service = useState(location.state.service);
  const isLoggedIn = useAuth().ensureLoggedIn();
  const [openedPodLogs, setOpenedPodLogs] = useState(null);

    useEffect(() => {
        async function loadData() {
            let response = await UsersApi.getMe();
            setUser(response.data.user);
        }
        loadData();
    }, []);

    const goToAllServices = () => {
        navigate('/services', { state: { "namespace": namespace[0] } });
    }

    const handlePodLogsClose = () => {
        setOpenedPodLogs(null);
    }

    const getLogs = async (pod_name) => {
        setOpenedPodLogs({pod: pod_name, namespace: namespace[0]});
    }


    const Transition = forwardRef(function Transition(props, ref) {
        return <Fade ref={ref} {...props} />;
    });

  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  return (
    <DashboardLayout>
      <Header />
        {openedPodLogs != null && <Dialog
            open={true}
            onClose={handlePodLogsClose}
            fullWidth
            maxWidth={false}
            TransitionComponent={Transition}
            transitionDuration={300}
            keepMounted
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ marginLeft: "6%", marginRight: "6%" }}
        >
            <LogsView closeView={handlePodLogsClose} service={openedPodLogs} />
        </Dialog>}
        <ArgonBox mt={10} mb={3}>
            <ArgonBox mb={5}>
                <Card>
                    <Grid container sx={{ p: 1 }}>
                        <Grid item xs={4} textAlign={"start"}>
                            <ArgonBox>
                                <ArgonButton variant="gradient" color="info" onClick={() =>  goToAllServices()}>
                                    <Icon>arrow_back</Icon> Back
                                </ArgonButton>
                            </ArgonBox>
                        </Grid>
                        <Grid item xs={4} textAlign={"center"}>
                            <ArgonBox>
                                <ArgonTypography variant="h3" textTransform="uppercase"  sx={"text-align: center"}>{service[0]}</ArgonTypography>
                            </ArgonBox>
                        </Grid>
                        <Grid item xs={4} textAlign={"end"}>
                        </Grid>
                    </Grid>
                </Card>
            </ArgonBox>
        </ArgonBox>
        <CounterInformation namespace={namespace[0]} service={service[0]}/>
        <TablePods namespace={namespace[0]} service={service[0]} getLogs={getLogs}/>
      <Footer />
    </DashboardLayout>
  );
}


export default ServiceInformation;
