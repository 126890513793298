import ArgonBox from "components/ArgonBox";
import {useEffect, useRef, useState} from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import {ManagementServices} from "../../../api/BackendApi/ManagementServices";
import Grid from "@mui/material/Grid";
import ArgonTypography from "../../../components/ArgonTypography";
import ReactEcharts from "echarts-for-react";
import {CircularProgress} from "@mui/material";

function CounterInformation({namespace, service}) {

  const mounted = useRef(false);

  const [countCluster, setCountCluster] = useState(0);
  const [countNode, setCountNode] = useState(0);
  const [countMinPod, setCountMinPod] = useState(0);
  const [countMaxPod, setCountMaxPod] = useState(0);
  const [countReplicasPod, setCountReplicasPod] = useState(0);
  const [percentageCPUUsage, setPercentageCPUUsage] = useState(0);
  const [percentageMemUsage, setPercentageMemUsage] = useState(0);

    useEffect(() => {
        async function loadData() {
            mounted.current = true;
            await updateLoop();
        }
        loadData();
    }, []);

    const updateLoop = async () => {
        try {
            if (!mounted.current) return;
            let response_cluster_info = await ManagementServices.get_cluster_info(namespace, service);
            let data = response_cluster_info.data.cluster_info[0]
            const total_cpu_usage = +(data.total_cpu_usage)?.toFixed(2);
            const total_memory_usage = +(data.total_memory_usage)?.toFixed(2);

            if(data) {
                setCountCluster(data.cluster_count);
                setCountNode(data.node_count);
                setCountMinPod(data.min_pods);
                setCountMaxPod(data.max_pods);
                setCountReplicasPod(data.replicas);
                setPercentageCPUUsage(total_cpu_usage);
                setPercentageMemUsage(total_memory_usage);
            }
        } finally {
            setTimeout(updateLoop, 3000);
        }
    }

    const option = {
        series: [
            {
                type: 'gauge',
                center: ['50%', '60%'],
                startAngle: 200,
                endAngle: -20,
                min: 0,
                max: 100,
                splitNumber: 10,
                itemStyle: {
                    color: '#FFAB91'
                },
                progress: {
                    show: true,
                    width: 30
                },
                pointer: {
                    show: false
                },
                axisLine: {
                    lineStyle: {
                        width: 30
                    }
                },
                axisTick: {
                    distance: -45,
                    splitNumber: 5,
                    lineStyle: {
                        width: 2,
                        color: '#999'
                    }
                },
                splitLine: {
                    distance: -52,
                    length: 14,
                    lineStyle: {
                        width: 3,
                        color: '#999'
                    }
                },
                axisLabel: {
                    distance: -20,
                    color: '#999',
                    fontSize: 20
                },
                anchor: {
                    show: false
                },
                title: {
                    show: false
                },
                detail: {
                    valueAnimation: true,
                    width: '60%',
                    lineHeight: 40,
                    borderRadius: 8,
                    offsetCenter: [0, '-15%'],
                    fontSize: 50,
                    fontWeight: 'bolder',
                    formatter: '{value} %',
                    color: 'inherit'
                },
                data: [
                    {
                        value: percentageCPUUsage
                    }
                ]
            },
            {
                type: 'gauge',
                center: ['50%', '60%'],
                startAngle: 200,
                endAngle: -20,
                min: 0,
                max: 100,
                itemStyle: {
                    color: '#FD7347'
                },
                progress: {
                    show: true,
                    width: 8
                },
                pointer: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: false
                },
                axisLabel: {
                    show: false
                },
                detail: {
                    show: false
                },
                data: [
                    {
                        value: percentageCPUUsage
                    }
                ]
            }
        ]
    };

    const option2 = {
        series: [
            {
                type: 'gauge',
                center: ['50%', '60%'],
                startAngle: 200,
                endAngle: -20,
                min: 0,
                max: 100,
                splitNumber: 10,
                itemStyle: {
                    color: '#FFAB91'
                },
                progress: {
                    show: true,
                    width: 30
                },
                pointer: {
                    show: false
                },
                axisLine: {
                    lineStyle: {
                        width: 30
                    }
                },
                axisTick: {
                    distance: -45,
                    splitNumber: 5,
                    lineStyle: {
                        width: 2,
                        color: '#999'
                    }
                },
                splitLine: {
                    distance: -52,
                    length: 14,
                    lineStyle: {
                        width: 3,
                        color: '#999'
                    }
                },
                axisLabel: {
                    distance: -20,
                    color: '#999',
                    fontSize: 20
                },
                anchor: {
                    show: false
                },
                title: {
                    show: false
                },
                detail: {
                    valueAnimation: true,
                    width: '60%',
                    lineHeight: 40,
                    borderRadius: 8,
                    offsetCenter: [0, '-15%'],
                    fontSize: 50,
                    fontWeight: 'bolder',
                    formatter: '{value} %',
                    color: 'inherit'
                },
                data: [
                    {
                        value: percentageMemUsage
                    }
                ]
            },
            {
                type: 'gauge',
                center: ['50%', '60%'],
                startAngle: 200,
                endAngle: -20,
                min: 0,
                max: 100,
                itemStyle: {
                    color: '#FD7347'
                },
                progress: {
                    show: true,
                    width: 8
                },
                pointer: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: false
                },
                axisLabel: {
                    show: false
                },
                detail: {
                    show: false
                },
                data: [
                    {
                        value: percentageMemUsage
                    }
                ]
            }
        ]
    };

    let body = <Grid container justifyContent="center" sx={{marginTop: 20, marginBottom: 20}}>
        <CircularProgress></CircularProgress>
    </Grid>

    if(countCluster > 0) {
        body = (
            <div>
                <ArgonBox mt={5}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Card>
                                <ArgonBox
                                    position="relative"
                                    pt={3}
                                    pb={6}
                                    px={3}
                                    textAlign="center"
                                    bgColor={"white"}
                                    variant="gradient"
                                >
                                    <ArgonBox position="relative" zIndex={1}>
                                        <ArgonTypography variant="h5" color={"dark"} gutterBottom>
                                            Clusters
                                        </ArgonTypography>
                                        <ArgonTypography
                                            variant="h1"
                                            fontWeight="bold"
                                            color={"dark"}
                                        >
                                            <ArgonBox component="small" color={"dark"}>
                                                <Icon>storage</Icon>
                                            </ArgonBox>
                                            {countCluster}
                                        </ArgonTypography>
                                    </ArgonBox>
                                </ArgonBox>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card>
                                <ArgonBox
                                    position="relative"
                                    pt={3}
                                    pb={6}
                                    px={3}
                                    textAlign="center"
                                    bgColor={"white"}
                                    variant="gradient"
                                >
                                    <ArgonBox position="relative" zIndex={1}>
                                        <ArgonTypography variant="h5" color={"dark"} gutterBottom>
                                            Nodes
                                        </ArgonTypography>
                                        <ArgonTypography
                                            variant="h1"
                                            fontWeight="bold"
                                            color={"dark"}
                                        >
                                            <ArgonBox component="small" color={"dark"}>
                                                <Icon>personal_video</Icon>
                                            </ArgonBox>
                                            {countNode}
                                        </ArgonTypography>
                                    </ArgonBox>
                                </ArgonBox>
                            </Card>
                        </Grid>
                    </Grid>
                </ArgonBox>
                <ArgonBox mt={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Card>
                                <ArgonBox
                                    position="relative"
                                    pt={3}
                                    pb={6}
                                    px={3}
                                    textAlign="center"
                                    bgColor={"white"}
                                    variant="gradient"
                                >
                                    <ArgonBox position="relative" zIndex={1}>
                                        <ArgonTypography variant="h5" color={"dark"} gutterBottom>
                                            Min Pods
                                        </ArgonTypography>
                                        <ArgonTypography
                                            variant="h1"
                                            fontWeight="bold"
                                            color={"dark"}
                                        >
                                            <ArgonBox component="small" color={"dark"}>
                                                <Icon>view_in_ar</Icon>
                                            </ArgonBox>
                                            {countMinPod}
                                        </ArgonTypography>
                                    </ArgonBox>
                                </ArgonBox>
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card>
                                <ArgonBox
                                    position="relative"
                                    pt={3}
                                    pb={6}
                                    px={3}
                                    textAlign="center"
                                    bgColor={"white"}
                                    variant="gradient"
                                >
                                    <ArgonBox position="relative" zIndex={1}>
                                        <ArgonTypography variant="h5" color={"dark"} gutterBottom>
                                            Max Pods
                                        </ArgonTypography>
                                        <ArgonTypography
                                            variant="h1"
                                            fontWeight="bold"
                                            color={"dark"}
                                        >
                                            <ArgonBox component="small" color={"dark"}>
                                                <Icon>view_in_ar</Icon>
                                            </ArgonBox>
                                            {countMaxPod}
                                        </ArgonTypography>
                                    </ArgonBox>
                                </ArgonBox>
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card>
                                <ArgonBox
                                    position="relative"
                                    pt={3}
                                    pb={6}
                                    px={3}
                                    textAlign="center"
                                    bgColor={"white"}
                                    variant="gradient"
                                >
                                    <ArgonBox position="relative" zIndex={1}>
                                        <ArgonTypography variant="h5" color={"dark"} gutterBottom>
                                            Replicas
                                        </ArgonTypography>
                                        <ArgonTypography
                                            variant="h1"
                                            fontWeight="bold"
                                            color={"dark"}
                                        >
                                            <ArgonBox component="small" color={"dark"}>
                                                <Icon>view_in_ar</Icon>
                                            </ArgonBox>
                                            {countReplicasPod}
                                        </ArgonTypography>
                                    </ArgonBox>
                                </ArgonBox>
                            </Card>
                        </Grid>
                    </Grid>
                </ArgonBox>
                <ArgonBox mt={5} mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Card sx={{ paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px" }}>
                                <ArgonBox position="relative" textAlign="center" zIndex={1}>
                                    <ArgonTypography variant="h4" color={"dark"} gutterBottom>
                                        CPU Usage
                                    </ArgonTypography>
                                </ArgonBox>
                                <ReactEcharts option={option} style={{ height: '400px' }} />
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card sx={{ paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px" }}>
                                <ArgonBox position="relative" textAlign="center" zIndex={1}>
                                    <ArgonTypography variant="h4" color={"dark"} gutterBottom>
                                        Memory Usage
                                    </ArgonTypography>
                                </ArgonBox>
                                <ReactEcharts option={option2} style={{ height: '400px' }} />
                            </Card>
                        </Grid>
                    </Grid>
                </ArgonBox>
            </div>
        );
    }

  return (
      body
  );
}


export default CounterInformation;
